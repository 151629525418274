import { render, staticRenderFns } from "./videoOld2.vue?vue&type=template&id=24b624d2&scoped=true&"
import script from "./videoOld2.vue?vue&type=script&lang=js&"
export * from "./videoOld2.vue?vue&type=script&lang=js&"
import style0 from "./videoOld2.vue?vue&type=style&index=0&id=24b624d2&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24b624d2",
  null
  
)

export default component.exports