<template>
  <div id="videoPage" >
    <div class="container">
      <video
        id="videoBox"
        class="video-box video-js vjs-default-skin"
        controls
        playsinline
      ></video>
      <div v-if="!vrObj"  @click.stop="ios13granted" style="width:100%;height: 100%;display: flex;align-items: center;justify-content: center;position: fixed;left: 0;top: 0;">
        <img style="height: 200px" :src="'./player/plugin/videoToolBar/style/begin-web.png'" >
      </div>
    </div>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import 'videojs-xr';
export default {
  name: 'videoPage',
  components: {
  },
  data () {
    return {
      id: '',
      detail: {},
      source: {
        1: '',
        2: ''
      },
      vrObj: null
    };
  },
  created () {
  },
  mounted () {
    const { name, id } = this.$route.query;
    this.id = id;
    this.$route.meta.title = decodeURIComponent(name);
    // console.log(mxreality, THREE);
    this.ios13granted();

    /* {
    * forceCardboard: false, //强制卡板
    * motionControls: true, //是否应启用运动/陀螺仪控制。
    * projection: 'auto' //'AUTO'自动, 场景'180'半球形, '180_LR'并排 180 度视频 视频是半球状的, '180_MONO'单视场 180 度视频 视频是半球状, （'360', 'Sphere', or'equirectangular'）球体,('Cube' or '360_CUBE')立方体, 'NONE'不是 360 度全景视频
    * sphereDetail: 32, //改变球状网格中投射等距矩形视频的段数
    * mediainfo: { //在逐个源的基础上进行设置
    *   projection: ''
    * }
    * debug: true, //启用调试日志记录
    * omnitone: object, //将 Omnitone 库对象传递给插件 https://github.com/GoogleChrome/omnitone
    * omnitoneOptions: object, //Omnitone 库的默认选项
    * disableTogglePlay: false //手动禁用切换播放的功能
    * }
    * */
    // player.vr();
  },
  watch: {},
  methods: {
    ios13granted () {
      const { init, vrObj } = this;
      let isIphone = navigator.userAgent.match(/(iPhone|iPod|iPad);?/i);
      if (vrObj) {
        return false;
      }
      console.log(88888);
      if (isIphone && typeof DeviceMotionEvent.requestPermission === 'function') {
        DeviceMotionEvent.requestPermission().then((permissionState) => {
          if (permissionState === 'granted') {
            init();
            // window.addEventListener('devicemotion', () => {
            // });
          }
        }).catch((error) => {
          init();
          alert('44444' + error);
        });
      } else {
        init();
        // 处理常规的非iOS 13+设备
        console.log('处理常规的非iOS 13+设备');
      }
    },
    init () {
      const player = videojs('videoBox', {
        sources: [{
          src: 'https://vravo-1258661974.cos.accelerate.myqcloud.com/shinkai%20eri%20main%20ver1~1920i.mp4'
        }]
      });
      // player.vr({ projection: '360', motionControls: true });
      console.log(player.xr({ projection: '360', motionControls: true }));
      this.vrObj = true;
    },
    orientTip () {
      const { $utils: { toast } } = this;
      if (this.getOrient) {
        toast({
          message: '横画面モードをオンにして、より効果的な体験をしてください。',
          duration: 2000
        });
      }
    },
    getOrient () {
      return window.orientation === 0 || window.orientation === 180;
    }
  }
};
</script>
<style scoped lang="less">
  #videoPage {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /deep/
    .top-nav-bar {
      flex-shrink: 0;
      .van-nav-bar {
        height: 44px;
        background-color: #1b1c1d;

        .van-nav-bar__content {
          height: 100%;
        }

        &::after {
          border: none;
        }

        .van-nav-bar__left {
          left: 0;
        }

        .van-nav-bar__left,
        .van-nav-bar__right {
          z-index: 999;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 44px;
          padding: 0 15px;
        }

        .back-arrow {
          width: 16px;
          height: 16px;
          background-image: url("~@/assets/images/icon_arrowLeft_01.png");
        }

        .right {
          display: flex;
          align-items: center;

          .icon {
            width: 24px;
            height: 24px;

            &.icon-menu {
              background-image: url("~@/assets/images/icon_menu_01.png");
            }
          }
        }
      }
    }
    .container{
      flex: 1;
      height: 0;
      font-size: 16px;
      .video-box{
        width: 100%;
        height: 100%;
      }
    }
    .iphoneX {
      flex-shrink: 0;
    }
  }

  /* 竖屏 */
  /*@media screen and (orientation: portrait) {*/
    /*#videoPage {*/
      /*width: 100vh;*/
      /*height: 100vw;*/
      /*transform: rotateZ( 90deg );*/
      /*transform-origin: 50vw;*/
      /*.iphoneX {*/
        /*display: none;*/
      /*}*/
    /*}*/
  /*}*/
</style>